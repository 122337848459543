import React from "react";
import { graphql } from "gatsby";
import LinkTile from '../components/linkTile'
import SidebarLayout from "../components/sidebarLayout";

const Slides = ({ data, pageContext}) => {
  return (
    <SidebarLayout pageContext={pageContext}
    main={(
        <div >
            <h1 >
              Folien
            </h1>   
            <ul>   
            {data.allFile.edges.map( node =>(
              <li><a href={`https://captainabap.github.io/slides/${node.node.relativePath.replace('.md', '.html')}`}>
            
                {node.node.relativePath}</a></li>
            ))}
            </ul>
        </div>
    )}
        sidebar={(<div>
          
          <LinkTile to='/login' text='Login' subtext='Anmeldung für registrierte User' ></LinkTile>
        </div>)}
        />
  )
}
export default Slides


export var query = graphql`
query Slides($eq: String = ".md") {
  allFile(filter: {ext: {eq: $eq}}) {
    edges {
      node {
        base
        relativePath
      }
    }
  }
}
`
