import * as React from 'react'
import * as Styles from '../styles/layout.module.scss'
import Layout from './layout'

const SidebarLayout = ({ title, 
                  pageContext, 
                  hideTitle,
                  main,
                  sidebar,
                  locale,
                  translationContext
                   }) => { 
  return (

    <Layout title={title}
            hideTitle={hideTitle}
            pageContext={pageContext}
            locale={locale}
            translationContext={translationContext}>

      <div class={Styles.sidebarLayout} >


        <main>          
          {main}
        </main>
        <div class={Styles.sidebar}>
          {sidebar}
        </div>

      </div>
    </Layout>

  )
}
export default SidebarLayout